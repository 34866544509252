import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import { mapBlogs } from '../../lib/utility';
import { img, imgBrand, alt, isBe } from '../../lib/brand';
import BlogRoll from '../../components/BlogRoll';
import Layout from '../../components/Layout';
import Header from '../../components/Header';
import ProductHero from '../../components/ProductHero';
import FindLO from '../../components/FindLO';
import Calculators from '../../components/Calculators';
import ProductDetails from '../../components/ProductDetails';
import StaticText from '../../components/StaticText';
import styles from './styles.module.less';

var background = img('usdaLoanbg.jpg');
var icon = imgBrand('typesImg/Icons_USDA_active.png');

const USDALoan = ({ staticTextItems }) => {
   const data = useStaticQuery(graphql`
      query USDALoanQuery {
         corpBlogs: allBlog(
            sort: { fields: [date], order: DESC }
            filter: { type: { eq: "corporate" }, tags: { in: ["USDA Loans"] } }
         ) {
            edges {
               node {
                  id
                  html
                  slug
                  title
                  date
                  feature
                  categories
                  tags
                  image
                  author {
                     slug
                     profile {
                        name
                     }
                     gallery {
                        photo
                     }
                  }
                  date
                  feature
               }
            }
         }
      }
   `);
   const header = <Header active={['usda-home-loan']} />;
   const intro = 'USDA Home Loan';
   const subhead = (
      <div>
         Helping to fund your piece
         <br /> of the American Dream.
      </div>
   );

   const { corpBlogs } = data;
   const { edges: corpBlobPosts = [] } = corpBlogs || {};
   const corporateBlogs = mapBlogs(corpBlobPosts);

   return (
      <Layout hasHero={true} header={header}>
         <Helmet>
            <title>{alt('USDA Home Loan')}</title>
            <meta
               name="description"
               content="A USDA loan is a mortgage with up to 100% financing for owner-occupied properties in designated USDA rural areas. Want to find out if you qualify? Contact us today."
            />
            <script
               src={`https://embed.signalintent.com/js/embedded.js?org-guid=${process.env.GATSBY_SIGNAL_INTENT_ORG_GUID}`}></script>
         </Helmet>
         <ProductHero
            customPhoto={background}
            icon={icon}
            header={intro}
            subhead={subhead}
            border={true}
            alt={alt('USDA Loans')}
         />
         <ProductDetails title={'What is a USDA home loan?'} border={true}>
            <p>
               The USDA loan program offers up to 100% financing for owner-occupied properties in designated USDA rural
               areas. Backed by the Department of Agriculture, USDA mortgage loans are popular with lower-income
               borrowers because of the loan’s lower interest rates and minimal down payments.
            </p>
         </ProductDetails>
         <ProductDetails title={'What are the benefits of a USDA loan?'} border={true}>
            <ul className={styles.list}>
               <li>Extremely low interest rates</li>
               <li>Little to no down payment</li>
               <li>Low monthly mortgage insurance</li>
            </ul>
         </ProductDetails>
         <ProductDetails title={'What are the current USDA loan rates?'} border={true}>
            <StaticText id="usda-home-loan-current-rates" items={staticTextItems} className={styles.staticText} />
         </ProductDetails>
         <ProductDetails title={'What are the USDA loan qualifications?'}>
            <StaticText id="usda-home-loan-qualifications" items={staticTextItems} className={styles.staticText} />
            <div>
               <p style={{ fontWeight: 'bold' }} className={styles.productSub}>
                  Some USDA loan requirements
               </p>
               <ul>
                  <li>US citizenship or permanent residency</li>
                  <li>Credit score of at least 640</li>
                  <li>Reliable income</li>
                  <li>History of repaying debt</li>
                  <li>The property serves as your primary residence</li>
                  <li>Adjusted household income is equal to or less than 115% of the median income in the area</li>
               </ul>
            </div>
         </ProductDetails>
         {isBe && (
            <>
               <Calculators
                  showCalculatorMortgage={true}
                  showCalculatorRentVsBuy={true}
                  showCalculatorHomeAffordability={true}
                  showCalculatorRequiredIncome={true}
               />
               <FindLO />
               {corporateBlogs.length >= 3 && (
                  <BlogRoll
                     corporateBlogs={corporateBlogs}
                     blogs={corporateBlogs}
                     showBlogs={true}
                     word="our"
                     url="usda-loans"
                  />
               )}
            </>
         )}
      </Layout>
   );
};

export default USDALoan;
